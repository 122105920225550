import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue, route as ziggyRoute } from '../../vendor/tightenco/ziggy';
import { i18nVue } from 'laravel-vue-i18n';
import VueGtag from 'vue-gtag';
import Bugsnag from '@bugsnag/js';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

window.route = (name, params, absolute, config) => {
    try {
        return ziggyRoute(name, params, absolute, config);
    } catch (e) {
        if (! e.message.includes('is not in the route list')) {
            throw e;
        }

        const locale = document.documentElement.lang;

        return ziggyRoute(`${locale}.${name}`, params, absolute, config);
    }
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        if (Bugsnag.isStarted()) {
            app.use(Bugsnag.getPlugin('vue'));
        }

        app.use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })
            .use(plugin)
            .use(ZiggyVue)
            .use({
                install(app) {
                    app.config.globalProperties.route = window.route;
                },
            })
            .use(VueGtag, {
                config: { id: 'GTM-KQDTGVLB' },
            })
            .mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
