import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import BugsnagPerformance from '@bugsnag/browser-performance';

const bugsnagApiKey = import.meta.env.VITE_BUGSNAG_API_KEY;

if (bugsnagApiKey) {
    Bugsnag.start({
        apiKey: bugsnagApiKey,
        plugins: [new BugsnagPluginVue()],
        releaseStage: import.meta.env.VITE_APP_ENV ?? 'production',
        appVersion: import.meta.env.VITE_APP_VERSION,
        rackInlineScripts: false,
        enabledBreadcrumbTypes: ['error', 'log']
    })
    BugsnagPerformance.start({ apiKey: bugsnagApiKey });
}

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.config = {
    deskReceiver: {
        isEnabled: import.meta.env.VITE_DESK_CONTROLLER_ENABLED !== 'false',
        host: import.meta.env.VITE_DESK_CONTROLLER_HOST ?? window.location.hostname,
        port: import.meta.env.VITE_DESK_CONTROLLER_PORT ?? window.location.port,
    },
};

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import './echo';
